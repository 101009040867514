"use strict";

import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLocation, useRouteError, useSearchParams } from "@remix-run/react";
import { useState, lazy, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { defaultOnError, shouldRetryOnError } from "~/domain/error";
import { useStore, visibilitySlice, visibilityInitialState } from "~/store";
import { AuthModal, ReloadOnLogout } from "~/features/Auth";
import { Footer } from "~/features/Footer";
import { ConnectModal } from "~/features/Mesra";
import { ConnectFlow } from "~/features/Mesra/ConnectMesra";
import { RootNavigation, RootNavigationRoute } from "~/features/Navigation";
import { GTMScript } from "./components/GTMScripts";
import { AnnouncementBar } from "./features/Announcement";
import { useUpdateEffect } from "@reach/utils";
import { isMiniApp } from "./shared/helpers";
import { runTimeConfig } from "./shared/runtime-config";
import { AuthRequiredRouteStateProvider } from "./features/Auth/AuthRequired";
import { EnableZendeskWidget } from "./features/Zendesk";
import { Banner as ErrorBoundaryBanner } from "./features/NotFound/NotFound";
import { ReCAPTCHAScript } from "./components/ReCAPTCHA";
import { useTranslation } from "react-i18next";
import { globalStyleHrefs, tailwindStyleHref } from "./styles";
export const meta = ({
  location
}) => {
  return [{
    title: "One app, all vehicle needs | Setel"
  }, {
    charSet: "utf-8"
  }, {
    name: "viewport",
    content: "width=device-width,initial-scale=1"
  }, {
    name: "description",
    content: `Experience the future of mobility. Fuel, parking, EV charging, eWallet, motor insurance, auto assistance and more, in one app for all vehicle needs`
  }, {
    property: "og:url",
    content: new URL(location.pathname, "https://www.setel.com").href
  }, {
    property: "og:type",
    content: "website"
  }, {
    property: "og:image",
    content: `https://www.setel.com/wp-content/uploads/2022/09/OG-image_redeem-Mesra-Rewards.png`
  }, {
    property: "og:image:width",
    content: "1200"
  }, {
    property: "og:image:height",
    content: "630"
  }, {
    property: "git-hash",
    content: runTimeConfig.COMMIT_HASH
  }, {
    property: "git-hash-short",
    content: runTimeConfig.SHORT_COMMIT_HASH
  }];
};
export const links = () => {
  return [...globalStyleHrefs.map(href => ({
    rel: "stylesheet",
    href
  })), {
    rel: "preconnect",
    href: "https://www.google.com"
  }, {
    rel: "preconnect",
    href: "https://www.gstatic.com",
    crossOrigin: "anonymous"
  }, /* Uses favicons from WP */
  {
    rel: "icon",
    href: "https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-32x32.png",
    sizes: "32x32"
  }, {
    rel: "icon",
    href: "https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-192x192.png",
    sizes: "192x192"
  }, {
    rel: "apple-touch-icon",
    href: "https://www.setel.com/wp-content/uploads/2022/02/cropped-favicon-180x180.png"
  }];
};
const Analytics = lazy(() => import("~/shared/hooks/useAnalyticsTracking").then(m => ({
  default: m.Analytics
})));
const initialStoreState = {
  visibility: visibilityInitialState
};
export default function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    i18n
  } = useTranslation();
  const isMiniAppRoute = isMiniApp(searchParams);
  const store = useStore(initialStoreState);
  useUpdateEffect(() => {
    store.dispatch(visibilitySlice.actions.closeAllExceptAuth());
  }, [location]);
  const [queryClient] = useState(() => {
    const client = new QueryClient({
      defaultOptions: {
        mutations: {
          onError: defaultOnError
        },
        queries: {
          onError: defaultOnError,
          refetchOnMount: false,
          retry: shouldRetryOnError
        }
      }
    });
    return client;
  });
  const isCardlessMesraLanding = location.pathname === RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE;
  return /* @__PURE__ */jsxs("html", {
    lang: i18n.language,
    children: [/* @__PURE__ */jsxs("head", {
      children: [/* @__PURE__ */jsx(Meta, {}), /* @__PURE__ */jsx(Links, {}), /* @__PURE__ */jsx("link", {
        rel: "stylesheet",
        href: (
        /* Tailwind styles should override everything
         * thus put it below <Links> (where route-based styles are placed)
         */
        tailwindStyleHref)
      })]
    }), /* @__PURE__ */jsxs("body", {
      "data-setel-page": "",
      children: [/* @__PURE__ */jsx(QueryClientProvider, {
        client: queryClient,
        children: /* @__PURE__ */jsxs(Provider, {
          store,
          children: [/* @__PURE__ */jsx(ReloadOnLogout, {}), isMiniAppRoute ? /* @__PURE__ */jsx(Outlet, {}) : isCardlessMesraLanding ? /* @__PURE__ */jsx(EnableZendeskWidget, {
            KEY: runTimeConfig.ZENDESK_WIDGET_CONFIG.KEY,
            ENABLED: runTimeConfig.ZENDESK_WIDGET_CONFIG.ENABLED,
            children: /* @__PURE__ */jsx(Outlet, {})
          }) : /* @__PURE__ */jsxs(Fragment, {
            children: [/* @__PURE__ */jsx(EnableZendeskWidget, {
              KEY: runTimeConfig.ZENDESK_WIDGET_CONFIG.KEY,
              ENABLED: runTimeConfig.ZENDESK_WIDGET_CONFIG.ENABLED,
              children: /* @__PURE__ */jsx(AuthRequiredRouteStateProvider, {
                children: ({
                  authRequiring
                }) => /* @__PURE__ */jsx("div", {
                  className: `${
                  // Hides the "shell" layout ONLY on small viewport
                  // when visting auth-required route (& not authenticated).
                  // This avoids layout jump where the banner/header shown briefly
                  // then <AuthModal> quickly overlap them (due to on mount trigger)
                  authRequiring ? "hidden md:flex" : "flex"} min-h-screen flex-col`,
                  children: /* @__PURE__ */jsxs(Suspense, {
                    children: [/* @__PURE__ */jsx(AnnouncementBar, {}), /* @__PURE__ */jsx(RootNavigation, {}), /* @__PURE__ */jsx(Outlet, {}), /* @__PURE__ */jsx(Footer, {
                      className: "mt-auto"
                    })]
                  })
                })
              })
            }), /* @__PURE__ */jsx(AuthModal, {}), /* @__PURE__ */jsx(ConnectModal, {
              flow: ConnectFlow.ACTIVATE
            }), /* @__PURE__ */jsx(ConnectModal, {
              flow: ConnectFlow.LINK
            })]
          }), /* @__PURE__ */jsx(Suspense, {
            children: /* @__PURE__ */jsx(Analytics, {})
          })]
        })
      }), /* @__PURE__ */jsx(ReCAPTCHAScript, {}), /* @__PURE__ */jsx(GTMScript, {
        id: runTimeConfig.ANALYTIC.GTM.TRACKING_ID
      }), /* @__PURE__ */jsx(ScrollRestoration, {}), /* @__PURE__ */jsx(Scripts, {})]
    })]
  });
}
export function HydrateFallback() {
  return /* @__PURE__ */jsxs("html", {
    lang: "en-GB",
    children: [/* @__PURE__ */jsxs("head", {
      children: [/* @__PURE__ */jsx(Meta, {}), /* @__PURE__ */jsx(Links, {}), /* @__PURE__ */jsx("link", {
        rel: "stylesheet",
        href: tailwindStyleHref
      })]
    }), /* @__PURE__ */jsxs("body", {
      "data-setel-page": "",
      children: [/* @__PURE__ */jsx("p", {
        className: "sr-only",
        children: "Loading..."
      }), /* @__PURE__ */jsx(Scripts, {})]
    })]
  });
}
export function ErrorBoundary() {
  const error = useRouteError();
  useEffect(() => {
    if (error && (error instanceof Error || typeof error === "object" && "message" in error)) {
      defaultOnError(error);
      return;
    }
    defaultOnError(new Error(`Unexpected: root ErrorBoundary caught a thrown value: ${error}`));
  }, [error]);
  return /* @__PURE__ */jsxs("html", {
    lang: "en-GB",
    children: [/* @__PURE__ */jsxs("head", {
      children: [/* @__PURE__ */jsx("title", {
        children: "Oh no!"
      }), /* @__PURE__ */jsx(Meta, {}), /* @__PURE__ */jsx(Links, {}), /* @__PURE__ */jsx("link", {
        rel: "stylesheet",
        href: tailwindStyleHref
      })]
    }), /* @__PURE__ */jsxs("body", {
      "data-setel-page": "",
      children: [/* @__PURE__ */jsx("div", {
        className: "h-screen flex flex-col",
        children: /* @__PURE__ */jsx(ErrorBoundaryBanner, {
          title: "Oh no!",
          description: "Something went wrong, we are fixing it.",
          className: "flex-auto"
        })
      }), /* @__PURE__ */jsx(Scripts, {})]
    })]
  });
}